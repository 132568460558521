@use '@angular/material' as mat;

$my-primary: mat.define-palette(mat.$gray-palette, 800);
$my-accent: mat.define-palette(mat.$light-blue-palette, 600, 800, A200);
$my-warn: mat.define-palette(mat.$red-palette, 900);
/* You can add global styles to this file, and also import other style files */
@import "./app/shared/css/functional.scss";
@import "./app/shared/css/colors.scss";
@import "./app/shared/css/common.scss";
@import "./app/shared/css/forms.scss";
@import "./app/shared/css/table.scss";
//@import '~@fortawesome/fontawesome-free/scss/fontawesome';
//@import '~ngx-ui-switch/ui-switch.component';
//@font-face {
//  font-family: 'FontAwesome';
//  src: url('~@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2') format('woff2'), /* Super Modern Browsers */
//       url('~@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff') format('woff')
//}
@font-face {
  font-family: 'OpenSansRegular';
  src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansItalic';
  src: url('fonts/OpenSans-Italic.ttf') format('truetype');
}




html, body {
  height: 100%;
  //Mat default overrides
  --mdc-checkbox-selected-focus-icon-color: var(--checkboxBlue);
  --mdc-checkbox-selected-hover-icon-color: var(--checkboxBlue);
  --mdc-checkbox-selected-icon-color: var(--checkboxBlue);
  --mdc-checkbox-selected-pressed-icon-color: var(--checkboxBlue);
  --mdc-checkbox-selected-focus-state-layer-color: var(--checkboxBlue);
  --mdc-checkbox-selected-hover-state-layer-color: var(--checkboxBlue);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--checkboxBlue);
  --mat-table-header-headline-font:  "Open Sans", sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font:  "Open Sans", sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 12px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font:  "Open Sans", sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 12px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--blue);
  --mat-tab-header-active-label-text-color: var(--blue);
  --mat-tab-header-active-ripple-color: var(--blue);
  --mat-tab-header-inactive-ripple-color:var(--blue);
  --mat-tab-header-active-focus-label-text-color: var(--blue);
  --mat-tab-header-active-hover-label-text-color: var(--blue);
  --mat-tab-header-active-focus-indicator-color: var(--blue);
  --mat-tab-header-active-hover-indicator-color: var(--blue);
}
.mat-mdc-row, .mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-table-row-item-label-text-font, Roboto, sans-serif);
  line-height: var(--mat-table-row-item-label-text-line-height);
  font-size: var(--mat-table-row-item-label-text-size, 14px);
  font-weight: var(--mat-table-row-item-label-text-weight);
}
body { margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: 'OpenSansRegular' !important;
}

.white-bg {
  background: white;
  color: black
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loader-icon {
  left: 45%;
  position: absolute !important;
  background-repeat: round;
  animation: none !important;
  top: 45%;
  background-image: url('./assets/images/loader-transparent.gif');
  z-index: 2000;
    circle {
      stroke: none;
    }
  &.inlineLoader {
    font-size: 50px;
  }
}
.loader-icon.export-loader{
  width: 20px;
  height: 20px;
  top: 20px;
  left: 83%;
}
