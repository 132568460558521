.media-form{

    &.add-input{
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper{ padding: 0 !important;}
    }
    .mat-form-field-appearance-outline {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-field-infix{ padding: 0; border-top: 0; min-height: 40px;  font-size: 14px !important; margin-top: 1rem;}
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-suffix { top: 0; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-field-flex { align-items: center; min-height: 29px; height: auto; margin: 0; }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-label-content { font-size: 12px; }
        .mat-datepicker-toggle-default-icon { height: auto !important; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline { top: 0; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline-end { border-radius: 0px !important; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline-start { border-radius: 0px !important; }
        .mat-mdc-select { position: initial; }
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-value { min-height: 30px; font-weight: normal; vertical-align: middle; font-size: 12px;font-family: 'OpenSansRegular'; }
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-arrow-wrapper { transform: none; display: table-cell; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper { padding-bottom: 10px; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-label { top: 2em; }
        input {
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5em;
        min-height: 28px;
        margin: 1px 0;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.1em) scale(.75);
        mat-label { background-color: var(--white); }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
        &.mat-form-field-type-mat-chip-list.mat-form-field-can-float,
        &.mat-chip-multi-select.mat-form-field-can-float,
        &.mat-form-field-type-mat-select.mat-form-field-can-float,
        &.mat-form-field-type-mat-input.mat-form-field-can-float {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-label-wrapper { top: -1.2em; }
        }
        &.mat-mdc-form-field {
        width: 100%;
        padding-left: 2px;
        &.mat-form-field-padding-bottom-0 {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-wrapper { padding-bottom: 0; }
        }
        .character-counter-container {
            top: -1em;
        }
        }
        &.mat-form-field-custom-outline {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline { display: none; }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper{
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex{
            border: 1px solid rgba(0,0,0,.12);
            }
            &:hover {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex{
                transition: .6s cubic-bezier(.25,.8,.25,1);
                border: 1px solid rgba(0,0,0,.87);
            }
            }
        }
        mat-label {
            background-color: var(--white);
            padding: 5px;
        }
        .mat-mdc-radio-button {
            margin-right: 1em;
            &:last-child { margin-right: 0; }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
            transform: translateY(-2.3em) translateX(0.5em) scale(0.75);
        }
        &.form-field-height-30 {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-wrapper {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex { padding: 0; }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-infix { padding: 0px 6px 0px 11px; }
            }
        }
        &.mat-form-field-radio-button-inline {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-infix{
            padding: 7px 5px 1px 10px;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-custom-label {
            transform: translateY(-2.5em) scale(0.75)
        }
        }
    }
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-input-control{
      font-size: 14px !important;
    }
    .cdk-overlay-pane {
      max-width: 100vw !important;
        .mat-mdc-select-panel { margin-bottom: 10px; margin-top: 11px; }
        .media-form-select-panel { margin-bottom: 10px; margin-top: 0;
          &.media-form-select-panel-no-label {margin-bottom: 3px; margin-top: 1px; }
        }
    }
    .mat-form-field-appearance-outline {
        input[disabled],
        select[disabled="true"],
        select[disabled]:not([disabled="false"]),
        input[disabled="true"].k-input,
        input[disabled].k-input:not([disabled="false"]) {
          background-color: transparent;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-trigger {
        min-height: 31px;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline{
        opacity: 1;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline-thick{
        color: var(--gray-border);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: var(--gray-border);
    }
    button:focus{
        outline: none;
    }
    .mat-datepicker-toggle .mat-mdc-icon-button{
        //height: 20px !important;
        //width: 15px !important;
        //margin-right: 2px;
        //font-size: 10px !important;
    }
}
.config-form{
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix { width: 144px; padding: .5em 0;}
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value { min-height: 30px; font-weight: normal; vertical-align: middle; font-size: 11px;font-family: 'OpenSansRegular'; }
}
.roles-form{
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper { margin:0px; }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-infix { width: 144px; padding-top: 0.1em;padding-bottom: 0.3em;}
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value { min-height: 29px; font-weight: normal; vertical-align: middle; font-size: 11px;font-family: 'OpenSansRegular'; }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper { top: 0.1em;}
}
.popupForm {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }
    textarea.mat-mdc-input-element {
      padding:0px;
      margin:0px;
  }
}/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow-wrapper {
    display: table-cell;
    vertical-align: middle;
    top: 0.1em;
}
.mat-mdc-autocomplete-panel
{
    .mat-mdc-option.mdc-list-item {
      font-size: 14px !important;
    }
  }

  .mat-mdc-select-panel .mat-mdc-option{

    font-size: 14px !important;
  }
