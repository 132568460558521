.mat-ink-bar{
	background-color: var(--white) !important;
	min-width:96px;
	height: 4px;
}

.mat-mdc-dialog-container{ padding: 0;}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  background-color: transparent!important;
}

.nav-links{
	.mat-mdc-tab-link {
		min-width: 116px;
	}
	.mat-mdc-tab-links{
		border-bottom: 1px solid #505050 !important;
	}
	.mat-ink-bar{
		background-color: #498BCF !important;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//.mat-tab-list a {
//	font-family: "OpenSansRegular";
//    font-size: 16px;
//	color: var(--silver);
//
//	&.mat-mdc-tab-link {
//		font-family: "OpenSansRegular";
//		color: var(--gray-border);
//		font-weight: 400;
//		letter-spacing: 0.5px;
//	}
//	&.active {
//    border-bottom: 3px solid var(--white);
//    color: #fff;
//		font-weight: 700;
//	}
//}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//.mat-select-arrow-wrapper{
//	top: 0px;
//    position: relative;
//}
.mat-mdc-header-cell {
  padding: 0 !important;
}

/* common Grid component css */ // mat-header-cell,
.mat-mdc-menu-panel {
  max-height: 500px!important;
  min-height: 200px !important;
}
mat-cell{
	padding: 0 3px !important;
	color: var(--black)!important;
  align-self:stretch; //added for grid line breaks issue fix.
}
mat-footer-cell{
	padding: 0 7px !important;
	color: var(--black)!important;
  align-self:stretch; //added for grid line breaks issue fix.
}
mat-header-cell{
  display: grid!important;
}
mat-header-cell:first-of-type {
  padding-left: 0px !important;
}
mat-header-cell:last-of-type {
  padding-right: 0px !important;
}
// Can be removed if we don't want sort icons to show unless they are hovered on
  .mat-sort-header-sorted .mat-sort-header-arrow {
	transform: none !important;
	opacity: 1 !important;
  }
  .mat-sort-header-arrow {
	transform: none !important;
	opacity: 1 !important;
  }
  .mat-sort-header-stem{
	height: 8px !important;
	width: 1px !important;
  }
 .mat-sort-header-pointer-left, .mat-sort-header-pointer-right{
	width: 6px !important;
	height: 1px !important;
  }
  .mat-mdc-cell{
	border-right: 1px solid var(--gray);
  }
  .mat-mdc-footer-cell{
    border-right: 1px solid var(--gray);
  }
  //for filter
  .mat-sort-header-container {
    margin-left: 1rem;
    width: 100%;
  }

/* common grid css end */

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-form-field-flex {
//	padding: 0 4px !important;
//}
input:-internal-autofill-selected  {
	background-color: var(--white) !important;
}
.mat-mdc-button{
	margin-top: 10px !important;
}
.mat-mdc-menu-content { padding: 0 !important; }
.mat-mdc-select-panel .mat-mdc-option{
	border-bottom: 1px solid var(--gray);
	font-size: 11px !important;
}
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-checked .mat-checkbox-background{
//  background-color: var(--checkboxBlue)!important;
//}
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-indeterminate .mat-checkbox-background{
//  background-color: var(--checkboxBlue)!important;
//}
///* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//  border-color: var(--checkboxBlue) !important;
//}
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: var(--checkboxBlue) !important;
}
.mat-sort-header-stem {
  //width: .5px !important;
  height: 6.9px!important;
}
.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  transform: translateY(0px) !important;
}
.mat-sort-header-pointer-left.ng-trigger.ng-trigger-leftPointer{
  transform: rotate(-45deg);
}
.mat-sort-header-pointer-right.ng-trigger.ng-trigger-rightPointer{
  transform: rotate(45deg);
}
// .mat-cell.mat-column-estimate{
// 	padding: 0 !important;
// 	align-items: initial;
// }
[mat-sort-header].cdk-program-focused .mat-sort-header-container{border-bottom:none!important}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//mat-table{
//  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//  .mat-checkbox-inner-container{
//    top: 4px;
//  }
//}

.common-grid-container .mat-mdc-input-element{
	border: 1px solid var(--gray);
}
.mat-mdc-button,.mat-mdc-icon-button,.mat-mdc-outlined-button, .mat-datepicker-toggle-active {
	color: #80868B;
}
.mat-calendar-body-selected, .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: var(--asofdateblue);
  color: #fff;
}
.dropEnd-month-picker{
  .mat-calendar-period-button[aria-label="Choose date"] {
    pointer-events: none !important;
    cursor: not-allowed;
  }
}

button:focus{ outline: none;}
.custom-switches{ .switch{background-color: #5C5C5C; color: white;} .switch.checked{background: #64bd63; padding: 0 3.5px;} .switch.disabled{ opacity: 0.8;} }

.stepper{
  font-family: "OpenSansRegular"; font-size: 16px; font-weight: bold;
  .mat-horizontal-stepper-header-container{
    .mat-step-header{ width: 50%; height: 36px; padding: 0; border: 2px solid #008CE3; background-color: var(--white);
       .mat-step-label { width: 100%; }
       .mat-step-text-label { color: #34567a; font-weight: bold; }
       .mat-step-icon{ display: none;}
    }
    .mat-stepper-horizontal-line{ border: transparent !important; }
    .mat-step-header:first-child{ margin-right: 5px; border-top-left-radius: 50px; border-bottom-left-radius: 50px;
       background-color: #308BD5 !important; .mat-step-text-label{color: var(--white); }}
    .mat-step-header:last-child{ margin-right: 5px; border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
  }
  .mat-horizontal-content-container{
    padding: 24px 0 0 0;
  }
}
.stepper_last_child{
  font-family: "OpenSansRegular"; font-size: 16px; font-weight: bold;
  .mat-horizontal-stepper-header-container{
    .mat-step-header{ width: 50%; height: 36px; padding: 0; border: 2px solid #008CE3; background-color: var(--white);
       .mat-step-label { width: 100%; }
       .mat-step-text-label { color: #34567a; font-weight: bold; }
       .mat-step-icon{ display: none;}
    }
    .mat-stepper-horizontal-line{ border: transparent !important; }
    .mat-step-header:first-child{ margin-right: 5px; border-top-left-radius: 50px; border-bottom-left-radius: 50px;
       }
    .mat-step-header:last-child{ margin-right: 5px; border-top-right-radius: 50px; border-bottom-right-radius: 50px;
      background-color: #308BD5 !important; .mat-step-text-label{color: var(--white); }}
  }
  .mat-horizontal-content-container{
    padding: 24px 0 0 0;
  }
}

.stepper-file-upload{
  //.mat-step-header[aria-labelledby="disabled_af"] {
  //  pointer-events: none !important;
  //  cursor: not-allowed;
  //}
}

// .stepper:first-child{ color: #34567A; background-color: var(--white) !important;}
.stepper-review {
  font-family: "OpenSansBold"; font-size: 16px; font-weight: bold;
  .mat-horizontal-stepper-header-container{
    .mat-step-header{ width: 50%; height: 36px; padding: 0;
      margin-right: 15px;
       //background-color: #308BD5;
       background-color: var(--white-smoke);
       .mat-step-label { width: 100%; }
       //.mat-step-text-label { color: var(--white); }
       .mat-step-text-label {
         color: #308BD5;
         div.review-text-area{
          height: 37px;
          line-height: 37px;
         }
        }
       .mat-step-icon{ display: none;}
      }
      .mat-step-header[aria-labelledby="disabled_af"] {
        pointer-events: none !important;
        cursor: not-allowed;
      }
      .mat-step-header .mat-step-header-ripple {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-left: 30px solid white;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        width: 0;
        height: 0;
        content: "";
      }
    div.mat-stepper-horizontal-line:nth-child(2){
        border-left: 30px solid #308BD5;
    }
    .mat-stepper-horizontal-line{
      z-index: 1;
      top: 0;
      right: -25px;
      display: block;
      border-left: 30px solid var(--white-smoke);
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      width: 0;
      height: 0;
      content: " ";
     }
    .mat-step-header:nth-child(1){
      border-top-left-radius: 50px; border-bottom-left-radius: 50px;
      .mat-step-header-ripple {
        border-left: 30px solid #308BD5;
      }
    }
    .mat-step-header:last-child{  border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
  }
  .mat-horizontal-content-container{
    padding: 24px 0 0 0;
    overflow: unset;
  }
  .mat-step-header:first-child {
    background-color: #308BD5 !important;
    .mat-step-text-label {
      color: var(--white) !important;
    }
  }

  .completed-bg{ background-color: #308BD5; .mat-step-text-label { color: var(--white);}}
}
.director-active, .finance-active, .snap-active{
  div.mat-stepper-horizontal-line:nth-child(4){
    border-left: 30px solid #308BD5;
  }
  .mat-step-header:nth-child(3) {
    background-color: #308BD5 !important;
    .mat-step-text-label {
      color: var(--white) !important;
    }
  }
}
.finance-active, .snap-active{
  div.mat-stepper-horizontal-line:nth-child(6){
    border-left: 30px solid #308BD5;
  }
  .mat-step-header:nth-child(5) {
    background-color: #308BD5 !important;
    .mat-step-text-label {
      color: var(--white) !important;
    }
  }
}

.snap-active{
  div.mat-stepper-horizontal-line:nth-child(8){
    border-left: 30px solid #308BD5;
  }
  .mat-step-header:nth-child(7) {
    background-color: #308BD5 !important;
    .mat-step-text-label {
      color: var(--white) !important;
    }
  }
}

.popupForm{
  .popupTitlediv {
    width:100%;
  }
  .popupBodydiv{
    max-height: 300px;
    overflow: auto;
  }
  .popuptitle{
    font-size: 20px;
    color: var(--black);
    margin-bottom: 10px;
    font-family: 'OpenSansSemiBold'; ;
  }
  .mat-mdc-select {
    font-size: 11px;
  }
  .mat-icon.close-icon{
    height: 16px;
    width: 16px;
    font-size: 16px;
    margin-right: 8px;
    color: var(--ford-gray);
    cursor: pointer;
  }
  .cancelBtn{
    //color: var(--pacific-blue);
    //height: 28px;
    //line-height: 1 !important;
    //width: 97px;
    //font-size: 12px;
    //margin-right: 6px;
    //font-family: 'OpenSansSemiBold';
  }
  .addBtn{
    color: var(--pacific-blue);
  }
  .mat-mdc-dialog-container{ padding: 15px; }
  mat-header-row{
    min-height:43px;
    background-color: whitesmoke;
    mat-header-cell{
      align-items:flex-end;
      padding-bottom:6px !important;
      color:var(--black);
      font-family: 'OpenSansRegular';
      padding-left: 6px !important;
    }
  }
  mat-cell{
    border-color: var(--gray);
    border-right-style: solid;
    border-right-width: thin;
    font-size: 12px;
    padding-left: 6px !important;
    font-family: "OpenSansRegular";
    align-items: center!important;
  }
  mat-cell:last-of-type {
    //border: none!important;
  }

  .error-icon{
    border-radius: 2px !important;
    padding: 2px;
    font-size: 11px;
    height: 16px;
    width: 16px;
    margin-left: 3px;
    background: var(--duplicateEstimate);
    color: var(--white);
    border-radius: 4px;
  }

  .warning-tooltip-icon{
    border-radius: 2px !important;
    padding: 2px;
    font-size: 11px;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    background: var(--warning-yellow);
    color: var(--white);
    border-radius: 4px;
  }
}

.accrualsGridBG {
  .common-grid-container{
   .lower-header{ min-height: 55px !important; }
   .first-row-header{ background-color: var(--white) !important;}
    mat-row{
      mat-cell:first-of-type{
        border-right:none !important;
        padding-left: 0px !important;
      }
    }
    mat-header-row{
      mat-header-cell.mat-column-menu{
        .filterRowContainer{
          margin-top:43px;
        }
      }
    }
  }
  .filterSpacing{
    min-height: 43px!important;
    display:flex;
}
}

.invoiceHistoryGrid {
  .filterSpacing{
    min-height:43px!important;
  }
}


.viewHistoryGridBG{
  .common-grid-container{
    .lower-header{ min-height: 30px !important; }
    .first-row-header{ background-color: var(--white) !important;}
    .mat-mdc-row{ min-height: 30px !important;
    .mat-mdc-cell{ align-self: stretch !important;}
    .mat-mdc-cell:last-child{ border-right: none !important;} }
   }
}

.mat-mdc-menu-item{
  font-family: 'OpenSansRegular' !important;
  font-size: 11px !important;
  &button.mat-mdc-menu-item:first-child{border-top: none; }
}

button.mat-mdc-menu-item{
  height: 35px !important;
  border-top: 1px solid var(--gray) !important;
  padding: 0px 10px 0px 10px;
}
