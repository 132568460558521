.commonSeperator {
	border-right: 2px solid var(--nero);
	margin: 0 2px;
	padding:1px 0;
	height: 14px;
	display: inline-block;
	position: relative;
    top: 2px;
}
.commonSeperatorSmall{
	border-right: 1px solid var(--nero);
	margin: 0 6px;
	padding:1px 0;
}

.primary-small-btn {
	//font-size: 12px;
	//color: var(--white) !important;
	//background-color: var(--blue);
	//height: 28px;
  //  line-height: 1 !important;
	//width: 97px;
	//font-family: 'OpenSansSemiBold';
}
.secondary-small-btn {
	//color: var(--blue);
  //  background-color:var(--white-smoke);
  //  height: 28px;
  //  line-height: 1 !important;
  //  width: 97px;
	//font-size: 12px;
	//font-family: 'OpenSansSemiBold';
}
input.estimate-amount{
    margin-top: 0;
}
.cdk-overlay-pane{
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
  max-width: 100vw !important;
}

.cdk-overlay-container{
	.cdk-global-overlay-wrapper{
		.cdk-overlay-pane{
			box-shadow: none;
		}
	}
}

.invoice-component {
	.row{
		padding: 10px 15px;
		display: flex;
	}
	header{
		padding: 32px 0px 8px;
		font-size: 24px;
		font-family: "OpenSansBold";
	}
	.nav-links a{
		color: var(--blue) !important;
		text-decoration: none !important;
		font-size: 14px;
		padding: 0 6px !important;
	}
}

.save-icon {
	&.iMessage {
		color: var(--green);
		font-size: 1.2em;
	}
}
