:root {
    --white: #fff;
    --black: #000;
    --blue: #498BCF;
    --gray: #E5E5E5;
    --white-smoke: #F6F6F6;
    --silver: #bdbdbd;
    --silver-two: #BEBEBE;
    --nero: #292929;
    --ford-gray: #979797;
    --pacific-blue: #0089D0;
    --night-rider: #2B2B2B;
    --vampire-black: #080808;
    --vivid-blue: #008BD6;
    --gray86: #dbdbdb;
    --gray91: #e8e8e8;
    --gray36: #5c5c5c;
    --green: #0CAF0C;
    --cancelIcon: #999;
    --calendar-blue: #008AD6;
    --gray-border: #DCDCDC;
    --edittedYellow:#F9B616;
    --disabledLink: #656565;
    --pencil-icon: #80868B;
    --warning-yellow: #F3CD63;
    --error-red: rgba(253,80,80,0.59);
    --duplicateEstimate:#FD5050;
    --checkboxBlue: #008CE3;
    --asofdateblue: #008ADC;
    --rowExpandArrow: #7F868C;
    --carouselHeader1: #E4E4E4;
    --carouselHeader2: #CCCED4;
    --expandedColumn: #D0D0D0;
    --accrualDropHeader:#b1afaf;
    --whiteSmoke:#F5F5F5;
    --darkGray:#505050;
    --lightGray: #9B9B9B;
    --chartHeaderGray: #505D6F;
}
