.floatRight{
  	float:right;
}

.p0 {	padding: 0 !important; }
.mb-25 { margin-bottom: 25px; }

.tooltip {
	background-color: #fff !important;
	color : #000 !important;
	padding: 5px 8px !important;
	max-width: 500px !important;
  text-align: left !important;
	&:after{
		border-color: #fff transparent transparent !important;
	}
}

.clearfix {
	clear: both;
}
// .tooltip-right:after {
// 	border-color: #fff !important;
// }
